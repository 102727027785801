video {
    src: url(/videos/video-2.mp4);
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.h3 {
    position:absolute;
    top: 255px;
    left: 285px;
    color: black;
    -webkit-text-stroke: 1px rgb(32, 181, 109);
    font-size: 4rem;
    font-family: Arial, Helvetica, sans-serif;
}

.iden {
    height: 200px;
    width: 200px;
}

.container {
    min-width: 400px;
    height: 100vh;
    align-items: center;
    box-sizing: border-box;
    background: var(--bg-color);
    padding: 50px;
}

.typewriter {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 56px;
    color: #fff;
    font-weight: bold;
    position: relative;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
}

.typewriter span {
    color: var(--text-color);
    text-transform: uppercase;
    padding: 10px;
    border-right: solid var(--text-color) 10px; 
    animation: cursor 1s ease-in-out infinite;
}

@keyframes cursor {
    from { border-color: var(--text-color); }
    to { border-color: transparent; }
}

.hero-container {
    background: url('../../images/image-home3.jpg') center center/cover no-repeat; 
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0, 0, 0.2);
    object-fit: contain;
}

.hero-container > h1 {
    color: #fff;
    font-size: 55px;
    margin-top: -100px;
    display: flex;
    text-align: center;
    transition: all .75s linear;
    -webkit-text-stroke: 2px whitesmoke;
    color: transparent;
    background-clip: padding-box;
    background-clip: padding-box;
    padding: 10px;
    border: 1px solid black;
    border-top: 2px solid #36454F;
    border-top: 2px solid #36454F;
    /*background-image: url(../../images/pngwing.com.png);*/
    
}

.hero-container > h1::before{
    content: "";
    position: relative;
    top: 0px;
    right: 0px;
    bottom: -80px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.25);
}

.hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucidia Sans Unicode', 'Lucia Grande', 'Lucidia Sans', Arial, sans-serif;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play.circle {
    margin-left: 4px;
}

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 70px;
        margin-top: -150px;
        
    }
}

@media screen and (max-width: 768px) {
    .hero-container > h1 {
        font-size: 50px;
        margin-top: -100px;
    }
}

.hero-container > p {
    font-size: 30px;
}

.btn-mobile {
    display: block;
    text-decoration: none;
}

.btn {
    width: 100%;
}

