
/* Everything below this is my experimentaion*/

/*

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.container {
  margin: 1rem;
  text-align: center;
}

.nav {
  background-image: linear-gradient(rgb(231, 116, 231) darkcyan);
  color: #282c34;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
}

.site-title {
font-size: 2rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav li:active {
  background-color: #555;
}

.nav li:hover {
  background-color: #99EDC3;
  border-radius: 50px;
}

*/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
  scrollbar-width: thin;
  scrollbar-color: auto;
}

/*.scrollable-element {
//  scrollbar-color: dark;
}*/

body::-webkit-scrollbar {
  width: 10px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: gray;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: auto;/*rgb(10, 10, 19)*/    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 1px solid rgb(26, 25, 23);  /* creates padding around scroll thumb */
}

.home,

.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.contact {
  background-image: url('/images/border-background.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}


.dark-mode {
  background-color: black;
  color: white;
}
.light-mode {
  background-color: white;
  color: black;
}

/* scroll to top button */
.top-btn-position {
  position: fixed;
  bottom: 15px;
  right: 20px;
}

.top-btn-style {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid #333;
  background-color: #fff;
  color: #333;
  cursor: pointer;
}

.top-btn-style:hover {
  animation: none;
  background-color: #333;
  color: #fff;
  border: 2px solid #fff;
}

