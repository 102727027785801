.not-found {
    background: url('../../images/404.jpg') center center/cover no-repeat; 
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0, 0, 0.2);
    object-fit: contain;
}

.not-found > h1 {
    margin-left: 50px;
    vertical-align:top;
    font-size: 100px;
    color: #fff;
    text-shadow:
        0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff,
        0 0 42px #5271ff,
        0 0 82px #5271ff,
        0 0 92px #5271ff,
        0 0 102px #5271ff,
        0 0 151px #5271ff;
}
.not-found > h2 {
    margin-left: 50px;
    color: #fff;
    text-shadow:
        0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff,
        0 0 42px #5271ff,
        0 0 82px #5271ff,
        0 0 92px #5271ff,
        0 0 102px #5271ff,
        0 0 151px #5271ff;
}

.not-found > h3 {
    margin-left: 50px;
    font-weight: bold;
    color:aqua;
}

.link-style{
    margin-left: 50px;
    font-size: x-large;
    text-decoration: none;
}

@media screen and (max-width: 960px) {
    .not-found > h1 {
        transition: all .75s linear;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }
}

@media screen and (max-width: 768px) {
    .not-found > h1 {
        transition: all .75s linear;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media screen and (max-width: 960px) {
    .not-found > h2 {
        transition: all .75s linear;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media screen and (max-width: 768px) {
    .not-found> h2 {
        transition: all .75s linear;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media screen and (max-width: 960px) {
    .not-found > h3 {
        transition: all .75s linear;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }
}

@media screen and (max-width: 768px) {
    .not-found > h3 {
        transition: all .75s linear;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}