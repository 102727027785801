  .container{
    width: 50vw;
    margin: 50px auto;
    background-color: #FFD6BA;
    -webkit-box-shadow: -5px 5px 0px 1px rgba(0, 0, 0, 0.250); 
    box-shadow: -5px 5px 0px 3px rgba(0, 0, 0, 0.250);
    
    h1{
        text-align: center;
        margin: 30px 0;
    }
  }
.btn{
  margin-top: 50px;
  margin-bottom: 50px;
  width: 200px;
}

@media screen and (max-width: 960px) {
  .btn {
    width: 150px;
      
  }
}

@media screen and (max-width: 768px) {
  .btn {
    width: 150px;
  }
}


  .form{
      background: url('../../images/contact.jpg') center center/cover no-repeat;
      text-align: center;
      height: 100vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: inset 0 0 0 1000px rgba(0,0, 0, 0.2);
      object-fit: contain;
  }
    
  

  .firstName{
    position: relative;
    text-align: center;
    width: 100px;
    height: 45px;
    border-style:groove;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .lastName{
    text-align: center;
    width: 100px;
    height: 45px;
    border-style:groove;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .emailField{
    text-align: center;
    width: 200px;
    height: 45px;
    border-style:groove;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .emailEntry{
    text-align: center;
    font-size: 35px;
    color:black;
  }

  .fields{
    text-align: center;
    width: 350px;
    height: 90px;
    border-style:groove;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }


  .name{
    margin: 25px auto;
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 90%;
    height: 40px;
    
    input[type="text"]{
        width: 40%;
        margin: 0 20px 0 0;
        padding: 15px;
        font-style: italic;    
        border: 2px solid #434753;
        background: transparent;
        color: #434753;
        outline-color: #FFD6BA;
    }

    label[for="firstName"]{
        position: absolute;
        top: -30px;
        left: 0;
    }

    label[for="lastName"]{
        position: absolute;
        top: -30px;
        right: 190px;
    }
    
  }

  .email{
    width: 50%;
    margin: 0 0 20px 35px;
    height: 40px;
    padding: 15px;
    font-style: italic;
    border: 2px solid #434753;
    background: transparent;
    color: #434753;
    outline-color: #FFD6BA;
          display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

  }

  //have you ever used this selector before

  label:not(#nameLabel){
    margin-left: 35px ;
    margin-bottom: 10px;
  }

  .message{
    width: 80%;
    margin: 0 35px;
    height: 100px;
    padding: 15px;
    font-style: italic;
    border: 2px solid #434753;
    background: transparent;
    color: #434753;
    outline-color: #FFD6BA;
  }

  .send{
    width: 100px;
    margin: 30px 35px;
    height: 40px;
    border: none;
    background: #434753;
    color: #FFD6BA;
    cursor: pointer;
  }
