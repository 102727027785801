.body {
    background-color: violet;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0, 0, 0.2);
    object-fit: contain;
}

.letters {
    position:absolute;
    top: 255px;
    left: 285px;
    color: black;
    -webkit-text-stroke: 1px rgb(32, 181, 109);
    font-size: 4rem;
    font-family: Arial, Helvetica, sans-serif;
}