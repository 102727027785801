/* {
    outline: 1px solid red;
}
*/
.grat-container {
    background: url('../../images/gratitude.jpg') center center/cover no-repeat; 
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0, 0, 0.2);
    object-fit: contain;
    
}

.grat-container > h1 {
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 80px;
    font-style: italic;
    font-weight: 100;
    text-align: center;
    margin-top: -80px;
    margin-right: 60px;
    color: #A7EB00;
    display: flex;
    text-align: center;
    object-fit: contain;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    
}

.grat-container > h2 {
    margin-right: 30px;
    color: paleturquoise;
    text-align: center;
    display: flex;
    text-align: center;
    padding-top: 10px;
    object-fit: contain;
    flex-wrap: wrap;

}

.grat-container > h3 {

    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 37px;
    font-style: italic;
    font-weight: 100;
    text-align: center;
    color: #fff;
    display: flex;
    text-align: center;
    object-fit: contain;
    flex-wrap: wrap;
    padding-top: 30px;
    box-sizing: border-box;
}

@media screen and (max-width: 960px) {
    .grat-container > h1 {
        font-size: 20px;
        margin-top: -100px;
        padding: 30px;
        display: flex;
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    .grat-container > h1 {
        font-size: 40px;
        margin-top: -100px;
        padding: 30px;
        display: flex;
        text-align: center;
    }
}

@media screen and (max-width: 960px) {
    .grat-container > h2 {
        font-size: 20px;
        margin-top: -50px;
        
    }
}

@media screen and (max-width: 768px) {
    .grat-container > h2 {
        font-size: 20px;
        margin-top: -10px;
    }
}

@media screen and (max-width: 960px) {
    .grat-container > h3 {
        font-size: 20px;
        margin-top: -20px;
        padding: 30px;
        display: flex;
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    .grat-container > h3 {
        font-size: 20px;
        margin-top: -20px;
        padding: 30px;
        display: flex;
        text-align: center;
    }
}