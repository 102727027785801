.about-main {
    background: url('../../images/about2.jpg') center center/cover no-repeat; 
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0, 0, 0.2);
    object-fit: contain;
  }

  .about-main > h1 {
    color: #fff;
    font-size: 40px;
    margin-top: -200px;
    display: flex;
    text-align: center;
    transition: all .75s linear;
    -webkit-text-stroke: 1px whitesmoke;
    color: transparent;
    background-clip: padding-box;
    background-clip: padding-box;
    padding: 10px;
    
}



.about-main > h1::before{
  content: "";
  position: relative;
  top: 0px;
  right: 0px;
  bottom: -80px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.25);
}

.amh2 {
  margin-top: 90px;
  color: #4cb7a5;
  font-size: 27px;
  display: flex;
  text-align: center;
  transition: all .75s linear;
  -webkit-text-stroke: 1px #4cb7a5;

  background-clip: padding-box;
  background-clip: padding-box;
  padding: 10px;
}

.amh3 {
  color: aquamarine;
  font-size: 26px;
  margin-top: 35px;
  display: flex;
  transition: all .75s linear;
  -webkit-text-stroke: 1px aquamarine;
  color: transparent;
  padding: 10px;
}

.amh4 {
  color: #007BB5;
  font-size: 26px;
  margin-top: 35px;
  display: flex;
  transition: all .75s linear;
  -webkit-text-stroke: 1px #007BB5;

  padding: 8px;

}
.amh41{
  color: silver;
  font-size: 32px;
  padding: 25px;
}

.amh3::before{
  content: "";
  position: relative;
  top: 0px;
  right: 0px;
  bottom: -80px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.25);
}

.about-main > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucidia Sans Unicode', 'Lucia Grande', 'Lucidia Sans', Arial, sans-serif;
} 

.about-btns {
  margin-top: 32px;
}

.about-btns .btn {
  margin: 6px;
}

@media screen and (max-width: 960px) {
  .about-main > h1 {
      font-size: 30px;
      margin-top: 100px;
      padding: 18px;
      display: flex;
      text-align: center;
      margin: 150px;
      padding-top: 100px;
      
  }
}

@media screen and (max-width: 768px) {
  .about-main > h1 {
      font-size: 30px;
      margin-top: 100px;
      padding: 18px;
      display: flex;
      text-align: center;
      margin: 20px;
      padding-top: 100px;
  }
}

@media screen and (max-width: 960px) {
  .amh2  {
      font-size: 20px;
      margin-top: -50px;
      padding: 35px;
      display: flex;
      text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .amh2 {
      font-size: 20px;
      margin-top: 5px;
      padding: 35px;
      display: flex;
      text-align: center;
  }
}
@media screen and (max-width: 960px) {
  .amh3  {
      font-size: 20px;
      margin-top: -40px;
      padding: 30px;
      display: flex;
      text-align: center;
      
  }
}

@media screen and (max-width: 768px) {
  .amh3 {
      font-size: 20px;
      margin-top: -40px;
      padding: 30px;
      display: flex;
      text-align: center;
  }
}

@media screen and (max-width: 960px) {
  .amh4  {
      font-size: 20px;
      margin-top: -40px;
      padding: 30px;
      display: flex;
      text-align: center;
      word-wrap:break-word;

      
  }
}

@media screen and (max-width: 768px) {
  .amh4 {
      font-size: 20px;
      margin-top: -40px;
      padding: 30px;
      display: flex;
      text-align: center;
      word-wrap:break-word;
  }
}

@media screen and (max-width: 960px) {
  .amh41  {
      font-size: 20px;
      margin-top: -40px;
      padding: 30px;
      display: flex;
      text-align: center;
      
  }
}

@media screen and (max-width: 768px) {
  .amh41 {
      font-size: 20px;
      margin-top: -40px;
      padding: 30px;
      display: flex;
      text-align: center;
  }
}

.about-main > p {
  font-size: 30px;
}

.btn-mobile {

  text-decoration: none;
}

.btn {
  width: 100%;
}

